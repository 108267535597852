import { SeaLang, Region } from '@/typings/common'

import CryptoJS from 'crypto-js'
import { loadScript, getHrefParam } from '@/utils'

const AES_KEY = 'BF73A4CD0AFA41DCA987A943C40AA5DA'

// declare function ysf(...args: any): void
export default class AiHelp {
  public static open = () => {
    window.AIHelpSupport?.show()
  }

  public static init = () => {
    // 地区
    const region: Region =
      (import.meta.env.MODE?.split('-')[1] as Region) || Region.HMT

    // 语言
    const language: SeaLang =
      (localStorage.getItem('i18nextLng') as SeaLang) || SeaLang.ZhTw

    const languageMap: {
      [key in SeaLang]: string
    } = {
      [SeaLang.En]: 'en',
      [SeaLang.In]: 'id',
      [SeaLang.Th]: 'th',
      [SeaLang.ZhCn]: 'zh-cn',
      [SeaLang.ZhTw]: 'zh-tw',
    }

    interface SupportConfig {
      alwaysShowHumanSupportButtonInBotPage: boolean
      welcomeMessage?: string
    }

    let initConfig: {
      appKey: string
      domain: string
      appId: string
      appName: string
      language: string
      supportMode: string
      supportConfig: SupportConfig
    }

    switch (region) {
      case Region.SEA:
        initConfig = {
          appKey: '333_app_046506b9756f41f49c50e34e5a18eca8',
          domain: '333.aihelp.net',
          appId: '333_platform_72f748de61f6ab64026307da9fc9e189',
          appName: 'Ragnarok Origin Global',
          language: languageMap[language] || 'en',
          supportMode: 'showConversation',
          supportConfig: {
            alwaysShowHumanSupportButtonInBotPage: true,
            // welcomeMessage: '親愛的冒險者，請問能幫上什麼忙呢(´,,•ω•,,)♡',
          },
        }
        break
      // case Region.HMT:
      default:
        initConfig = {
          appKey: 'TAIPEI_app_7b638aefab18402c9ca115f214f170b2',
          domain: 'taipei.aihelp.net',
          appId: 'taipei_platform_9aa675f80c80c562f4ade591df86090b',
          appName: '卡普拉客服',
          language: 'zh-TW',
          supportMode: 'showConversation',
          supportConfig: {
            alwaysShowHumanSupportButtonInBotPage: true,
            welcomeMessage: '親愛的冒險者，請問能幫上什麼忙呢(´,,•ω•,,)♡',
          },
        }
        break
    }

    const urlParams = getHrefParam()
    // let defaultOpen = false
    // if (
    //   (urlParams.showkf && Number(urlParams.showkf) === 1) ||
    //   (urlParams.showaihelp && Number(urlParams.showaihelp) === 1)
    // ) {
    //   defaultOpen = true
    // }
    loadScript('https://cdn.aihelp.net/webchatv3/aihelp.js', () => {
      window.AIHelpSupport?.init(initConfig)
      // if (defaultOpen) {
      //   setTimeout(() => {
      //     AiHelp.open()
      //   }, 500)
      // }
    })
  }

  public static close = () => {
    document
      .getElementById('AIHelpSupportDiv')
      ?.style.setProperty('display', 'none')
  }
}
