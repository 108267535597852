import { SeaLang, Region, LnaLang } from '@/typings/common'
import YSF from '@neysf/qiyu-web-sdk'
import CryptoJS from 'crypto-js'
import i18n from 'react-i18next'
import ta from 'thinkingdata-browser'
import { getHrefParam } from '../utils'

const AES_KEY = 'BF73A4CD0AFA41DCA987A943C40AA5DA'

// declare function ysf(...args: any): void

const REGION_LNG_MAP: {
  [key1 in Region]: { [key in string]: SeaLang | LnaLang }
} = {
  [Region.HMT]: {
    'zh-tw': SeaLang.ZhTw,
  },
  [Region.SEA]: {
    en: SeaLang.En,
    th: SeaLang.Th,
    in: SeaLang.In,
    'zh-cn': SeaLang.ZhCn,
    'zh-tw': SeaLang.ZhTw,
  },
  [Region.LNA]: {
    en: SeaLang.En,
    es: LnaLang.Es,
    pt: LnaLang.Pt,
  },
}

const fallbackLangugeMap: {
  [key1 in Region]: SeaLang | LnaLang
} = {
  [Region.HMT]: SeaLang.ZhTw,
  [Region.SEA]: SeaLang.En,
  [Region.LNA]: LnaLang.En,
}

const referrerMap: {
  [key1 in Region]: string
} = {
  [Region.HMT]: 'https://roo.gnjoy.hk',
  [Region.SEA]: 'https://roglobal.com',
  [Region.LNA]: 'https://lna.roglobal.com',
}

const qiyuRobotId: {
  [key1 in Region]: {
    [key2 in SeaLang | LnaLang]?: number
  }
} = {
  [Region.HMT]: {
    [SeaLang.ZhTw]: 5329348,
  },
  [Region.SEA]: {
    [SeaLang.En]: 5329368,
    [SeaLang.In]: 5329368,
    [SeaLang.Th]: 5328364,
    [SeaLang.ZhCn]: 5329348,
    [SeaLang.ZhTw]: 5329348,
  },
  [Region.LNA]: {
    [LnaLang.En]: 5328380,
    [LnaLang.Es]: 5328376,
    [LnaLang.Pt]: 5328377,
  },
}

const qiyuLanguageMap: {
  [key1 in Region]: {
    [key2 in SeaLang | LnaLang]?: string
  }
} = {
  [Region.HMT]: {
    [SeaLang.ZhTw]: 'zh-tw',
  },
  [Region.SEA]: {
    [SeaLang.En]: 'en',
    [SeaLang.In]: 'id',
    [SeaLang.Th]: 'th',
    [SeaLang.ZhCn]: 'zh-cn',
    [SeaLang.ZhTw]: 'zh-tw',
  },
  [Region.LNA]: {
    [LnaLang.En]: 'en',
    [LnaLang.Es]: 'es',
    [LnaLang.Pt]: 'pt',
  },
}

const qiyuQtypeMap: {
  [key1 in Region]: {
    [key2 in SeaLang | LnaLang]?: number
  }
} = {
  [Region.HMT]: {
    [SeaLang.ZhTw]: 4494011,
  },
  [Region.SEA]: {
    [SeaLang.ZhCn]: 4495019,
    [SeaLang.ZhTw]: 4495019,
    [SeaLang.Th]: 4495020,
    [SeaLang.En]: 4495021,
    [SeaLang.In]: 4495022,
  },
  [Region.LNA]: {
    [LnaLang.En]: 4494016,
    [LnaLang.Es]: 4495027,
    [LnaLang.Pt]: 4494015,
  },
}

const qiyuDialogStyleMap: {
  [key1 in Region]: {
    [key2 in SeaLang | LnaLang]?: number
  }
} = {
  [Region.HMT]: {
    [SeaLang.ZhTw]: 6637274,
  },
  [Region.SEA]: {
    [SeaLang.En]: 6637219,
    [SeaLang.Th]: 6637220,
    [SeaLang.In]: 6637219,
    [SeaLang.ZhCn]: 6638223,
    [SeaLang.ZhTw]: 6638270,
  },
  [Region.LNA]: {
    [LnaLang.En]: 6637257,
    [LnaLang.Es]: 6638238,
    [LnaLang.Pt]: 6638239,
  },
}

const encrypt = (word: string) => {
  const key = CryptoJS.enc.Utf8.parse(AES_KEY)
  const srcs = CryptoJS.enc.Utf8.parse(word)
  const encrypted = CryptoJS.AES.encrypt(srcs, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  })
  return encrypted.ciphertext.toString()
}

const decrypt = (word: string) => {
  const key = CryptoJS.enc.Utf8.parse(AES_KEY)
  const encryptedHexStr = CryptoJS.enc.Hex.parse(word)
  const encryptedBase64Str = CryptoJS.enc.Base64.stringify(encryptedHexStr)
  const decryptStr = CryptoJS.AES.decrypt(encryptedBase64Str, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  })
  return CryptoJS.enc.Utf8.stringify(decryptStr).toString()
}

export default class QY {
  public static open = () => {
    // 地区
    const region: Region =
      (import.meta.env.MODE?.split('-')[1] as Region) || Region.HMT
    console.log(region)
    const urlParams = getHrefParam()
    console.log('链接参数：', urlParams)
    const language: SeaLang | LnaLang =
      (urlParams.lng
        ? REGION_LNG_MAP[region][urlParams.lng as string]
        : (localStorage.getItem('i18nextLng') as SeaLang | LnaLang)) ||
      fallbackLangugeMap[region]
    console.log('language: ', language)
    if (urlParams.lng) {
      localStorage.setItem('i18nextLng', urlParams.lng as string)
    }
    if (urlParams.roleinfo) {
      try {
        const data = decrypt(urlParams.roleinfo as string)
        console.log(JSON.parse(data))
        const level = JSON.parse(data).find(
          (item) => item.key === 'level'
        )?.value
        console.log('open with info: ', {
          uid: urlParams.uid,
          data,
          level: level ? Number(level) : 0,
          language: qiyuLanguageMap[region][language],
          robotShuntSwitch: 1,
          robotId: qiyuRobotId[region][language],
          qtype: qiyuQtypeMap[region][language],
          title: `ROO official website ${region}`,
          referrer: referrerMap[region],
        })
        window.ysf('config', {
          uid: urlParams.uid,
          data,
          level: level ? Number(level) : 0,
          language: qiyuLanguageMap[region][language],
          robotShuntSwitch: 1,
          robotId: qiyuRobotId[region][language],
          qtype: qiyuQtypeMap[region][language],
          title: `ROO official website ${region}`,
          referrer: referrerMap[region],
          success: () => {
            ta?.track('QYConfigSuccess', {
              page: window.location.href,
              env: import.meta.env.MODE,
              uid: urlParams.uid,
              data,
              level: level ? Number(level) : 0,
              language: qiyuLanguageMap[region][language],
              robotShuntSwitch: 1,
              robotId: qiyuRobotId[region][language],
              qtype: qiyuQtypeMap[region][language],
              title: `ROO official website ${region}`,
              referrer: referrerMap[region],
            })
            window.ysf('open', {
              templateId: qiyuDialogStyleMap[region][language],
            })
          },
          error: () => {
            console.log('config error')
          },
        })
      } catch (error) {
        console.log(error)
      }
    } else {
      console.log('open without info: ', {
        language: qiyuLanguageMap[region][language],
        robotShuntSwitch: 1,
        robotId: qiyuRobotId[region][language],
        qtype: qiyuQtypeMap[region][language],
        title: `ROO official website ${region}`,
        referrer: referrerMap[region],
      })
      window.ysf('config', {
        language: qiyuLanguageMap[region][language],
        robotShuntSwitch: 1,
        robotId: qiyuRobotId[region][language],
        qtype: qiyuQtypeMap[region][language],
        title: `ROO official website ${region}`,
        referrer: referrerMap[region],
        success: () => {
          ta?.track('QYConfigSuccess', {
            page: window.location.href,
            env: import.meta.env.MODE,
            uid: urlParams.uid,
            language: qiyuLanguageMap[region][language],
            robotShuntSwitch: 1,
            robotId: qiyuRobotId[region][language],
            qtype: qiyuQtypeMap[region][language],
            title: `ROO official website ${region}`,
            referrer: referrerMap[region],
          })
          window.ysf('open', {
            templateId: qiyuDialogStyleMap[region][language],
          })
        },
      })
    }
  }

  public static init = () => {
    // const urlParams = getHrefParam()
    // let defaultOpen = false
    // if (urlParams.showkf && Number(urlParams.showkf) === 1) {
    //   defaultOpen = true
    // }
    YSF.init('a4229c03d964fa1da9e90541d9ed0870', {}, 'overseas').then((ysf) => {
      window.ysf = ysf
      // if (defaultOpen) {
      //   QY.open()
      // }
    })
  }

  public static close = () => {
    // 七鱼没有给关闭的接口，只能骚操作了
    document
      .getElementById('YSF-PANEL-INFO')
      ?.classList.remove('ysf-chat-layeropen')
  }
}
